<template>
  <div class="vownermanagement">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="患者姓名">
            <el-input
              v-model="form.patientName"
              placeholder="请输入患者姓名"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item label="患者电话">
            <el-input
              v-model="form.patientMobile"
              placeholder="请输入患者电话"
              clearable
            ></el-input>
          </el-form-item>
            <el-form-item label="提交时间" prop="disinfectTime">
            <el-date-picker
              v-model="form.startDate"
              type="date"
              placeholder="开始时间"
              label-width="10px"
              clearable
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="到" prop="disinfectTime">
            <el-date-picker
              v-model="form.endDate"
              type="date"
              placeholder="结束时间"
              clearable
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item label="用户账号">
            <el-input
              v-model="form.phone"
              placeholder="请输入用户账号"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item label="用户姓名">
            <el-input
              v-model="form.createUserName"
              placeholder="请输入用户姓名"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item label="医院名称">
            <el-select clearable="" v-model="form.hospitalId" placeholder="请选择">
              <el-option
                v-for="item in historyOptions"
                :key="item.uuid"
                :label="item.hospName"
                :value="item.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item label="科室">
            <el-select v-model="form.departmentId" placeholder="请选择科室" clearable>
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.departmentName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()"
              >搜索</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="reset()"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="patientName"
            label="患者姓名"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="patientAge"
            label="患者年龄"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="patientMobile"
            label="患者电话"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="departmentName"
            label="患者科室"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="patientBed"
            label="床位"
            align="center"
          ></el-table-column>

          <el-table-column prop="leaveTime" label="出院时间" align="center">
            <template slot-scope="scope">
              <span class="font_3">{{forTime(scope.row.leaveTime)  }}</span>
              <span v-if="scope.row.leaveTimeEnd" class="font_3"
                >至{{ forTime(scope.row.leaveTimeEnd) }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="patientCondition" label="出院方式" align="center">
            <template slot-scope="scope">
              <span>{{ direction(scope.row.leaveReason) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="patientCondition"
            label="基本病情"
            align="center"
          ></el-table-column>
          <el-table-column prop="remark" label="目的地" align="center"></el-table-column>
          <el-table-column
            prop="hospName"
            label="医院名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="deviceNeed"
            label="设备名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="replyContent"
            label="信息回复"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="createUserName"
            label="提交人"
            align="center"
          ></el-table-column>
          <el-table-column prop="phone" label="提交账号" align="center"></el-table-column>
          <el-table-column prop="createTime" label="提交时间" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button
                @click="edit(scope.row)"
                size="mini"
                type="primary"
                icon="el-icon-chat-dot-round"
                >回复</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10, 15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <el-dialog title="信息回复" :visible.sync="dialogVisible" width="30%">
        <el-input
          type="textarea"
          :rows="10"
          placeholder="请输入内容"
          v-model="replyContent"
        >
        </el-input>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="config()">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
import store from '@/store/index.js'

export default {
  name: 'Information',
  components: {},
  data () {
    return {
      tabData: [], // 表格数据源
      form: { }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      dialogVisible: false,
      replyContent: '',
      dataObject: {},
      directionList: [
        {
          value: 1,
          name: '康复回家'
        },
        {
          value: 2,
          name: '放弃治疗'
        },
        {
          value: 3,
          name: '转院'
        }
      ],
      departmentList: [],
      historyOptions: [] // 医院列表
    }
  },
  created () {
    this.crateMet()
  },
  mounted () {
    this.$eventBus.$on('message', () => {
      this.crateMet()
    })
  },
  computed: {
    direction () {
      return function (value) {
        if (value) {
          const obj = this.directionList.find((item) => item.value == value)
          return obj.name
        }
      }
    },
    forTime () {
      return function (value) {
        if (value) {
          return value.substr(0, 13)
        } else {
          return value
        }
      }
    }
  },
  methods: {
    /**
     * 初始调用接口
     */
    crateMet () {
      this.getAllCar()
      this.getdeparmenttions()
      this.getHistoryOptions()
    },
    /**
     * 医院列表
     */
    async getHistoryOptions () {
      const { data: res } = await this.$http.post('/pmService/hospital/list', {})
      this.historyOptions = res.data
    },
    // 获取科室信息
    async getdeparmenttions () {
      const { data: res } = await this.$http.post('/pmService/department/list', {
        pageSize: 1000, // 每页显示数据条数
        PageNo: 1 // 当前页数
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取科室列表失败')
      this.departmentList = res.data
      // this.form.companyName = this.institutionsOptions[0].uuid
      // this.getAllCar()
    },
    /**
     * 重置
     */
    reset () {
      this.paging.PageNo = 1
      for (const key in this.form) {
        this.form[key] = ''
      }
      this.getAllCar()
    },
    /**
     * 回复确定
     */
    async config () {
      const { data: res } = await this.$http.post(
        '/pmService/hospitalLeaveRecord/update',
        {
          ...this.dataObject,
          replyContent: this.replyContent,
          updateUser: sessionStorage.getItem('userId')
        }
      )
      if (res.statusCode == 200) {
        this.dialogVisible = false
        this.getAllCar()
      }
    },
    /**
     * 回复弹窗
     */
    edit (e) {
      this.dialogVisible = true
      this.dataObject = e
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取所有医护列表
    async getAllCar () {
      const { data: res } = await this.$http.post('/pmService/hospitalLeaveRecord/page', {
        size: this.paging.pageSize,
        current: this.paging.PageNo,
        ...this.form
        // size: 2,
        // current: 1
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取车主列表失败')
      this.tabData = res.data.records
      this.total = res.data.total
    },
    // 搜索
    search () {
      this.paging.PageNo = 1
      this.getAllCar()
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    }
  },
  beforeDestroy () {
    // 销毁监听事件
    this.$eventBus.$off('message')
  }

}
</script>
<style lang="less" scoped>
.vownermanagement {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
